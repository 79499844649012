import React from 'react'

import { Link } from 'react-router-dom'

import Navbar from 'react-bootstrap/Navbar'
import { Nav } from 'react-bootstrap'
import { NavDropdown } from 'react-bootstrap'
import { Breakpoint } from 'react-socks'

export default function DropdownNav() {
  const title = <span style={{color: '#F76A8C', fontWeight: '500'}}>Jump Here</span>
  return (
    <Breakpoint l up>
      <Navbar variant="light" bg="light" style={{zIndex: '1'}}>
      <Navbar.Brand id="brand"> 
        <Link className="nav-link" to='/' style={{color: '#F76A8C'}}>Kelley Michelle</Link>
      </Navbar.Brand>
      <Nav.Link target="_blank" rel="noopener noreferrer" href="http://blog.kelleymichelle.com" style={{color: '#F76A8C'}}>
        Blog
      </Nav.Link>
        <NavDropdown title={title} id="collapsible-nav-dropdown dropdown-link">
          <NavDropdown.Item id="dropdown-link" href="/">Home</NavDropdown.Item>
          <NavDropdown.Item id="dropdown-link" href="/about-me">About me</NavDropdown.Item>
          <NavDropdown.Item id="dropdown-link" href="/portfolio">Portfolio</NavDropdown.Item>
          <NavDropdown.Item id="dropdown-link" href="/contact">Contact Me</NavDropdown.Item>
        </NavDropdown>
      </Navbar>
    </Breakpoint>
  )
}