import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import ReactGA from 'react-ga'




import { BreakpointProvider, setDefaultBreakpoints } from 'react-socks'

import Home from './pages/Home'
import AboutMe from './pages/AboutMe'
import ContactMe from './pages/ContactMe'
import Portfolio from './pages/Portfolio'
import Blog from './pages/Blog'
// import Scroll from './pages/Scroll'

setDefaultBreakpoints([
  { xs: 0 },
  { s: 376 },
  { m: 426 },
  { l: 769 },
  { xl: 1025 }
])

ReactGA.initialize('UA-159646553-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <BreakpointProvider>
      <Router>
        <Route exact path="/" component={Home}/>
        <Route exact path="/portfolio" component={Portfolio} />
        <Route exact path="/about-me" component={AboutMe} />
        <Route exact path="/contact" component={ContactMe} />
        <Route exact path="/blog" component={Blog} />
      </Router>
    </BreakpointProvider>
  );
}

export default App;
