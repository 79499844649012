import React from 'react'

import { Breakpoint } from 'react-socks'

import Fade from 'react-reveal/Fade'

import { Link } from 'react-router-dom'

import Nabber from '../components/Nabber'
import DropdownNav from '../components/DropdownNav'
import PortfolioTileBox from '../components/PortfolioTileBox'
import CodeIcons from '../components/CodeIcons'
// import PortfolioCarousel from '../components/PortfolioCarousel'

import ReactGA from 'react-ga'

ReactGA.pageview(window.location.pathname + window.location.search);

export default function Portfolio() {

  // const mobileStyle = {backgroundImage: 'url(../images/portfolio.png)'}
  // const desktopStyle = {backgroundImage: 'url(../images/portfolio-mobile.png'}
  
  return (
    <>
      <Breakpoint m down>
        <Nabber/>
        <div className="portfolio mobile">
          <CodeIcons/>
          <div className="d-flex flex-column">
        {/* <center> */}
              <div style={{fontSize: '3.25em', marginTop: "3%", marginLeft: '17%', fontFamily: 'Gorditas, cursive', zIndex: '3'}}>Always Creating...</div>
             
            <div style={{color: 'black', fontSize: '16px', fontWeight: '700', marginLeft: '12%'}}>
              Scroll through my projects! <br/>Click a card to see more details.
            </div>
            <PortfolioTileBox/>
          
            
          
          </div>
      </div>
    </Breakpoint>

    <Breakpoint l up>
      <DropdownNav/>
      <Fade bottom duration={1000}>
      <div className="portfolio desktop">
        <CodeIcons/>
        <center>
          <div className="d-flex flex-column align-items-center">
            <div style={{fontSize: '7em', marginTop: "1%", fontFamily: 'Gorditas, cursive', zIndex: '3'}}>Always Creating...</div>
            
            <div style={{color: 'black', fontSize: '18.5px', fontWeight: '700', zIndex: '3'}}>
              Scroll through my projects! Click a card to see more details.
            </div>

              <PortfolioTileBox/>
            {/* </div> */}
          
              <Link to="/contact" style={{color: '#F76A8C', marginTop: '20px', fontSize: '2em', fontFamily: 'Gorditas, cursive', position: 'relative', zIndex: '5'}}>Click to Get in Touch</Link>
          </div >
            {/* <div style={{zIndex: '10'}}> */}
            {/* </div> */}
        </center>
      </div>
      </Fade>
    </Breakpoint>
   </>
  )
}