import React from 'react'
import bootstrapIcon from '../images/code-icons/bootstrap-icon.png'
import firebaseIcon from '../images/code-icons/firebase-icon.png'
import html5Icon from '../images/code-icons/html5-icon.png'
import jsIcon from '../images/code-icons/javascript-icon.png'
import mongoIcon from '../images/code-icons/mongodb-icon.png'
import nodeIcon from '../images/code-icons/nodejs-icon.png'
import reactIcon from '../images/code-icons/react-icon.png'
import reduxIcon from '../images/code-icons/redux-icon.png'
import rubyIcon from '../images/code-icons/ruby-icon.png'
import swift from '../images/code-icons/swift-icon.png'

export default function CodeIcons() {
  return (
    <div >
      <section>

        <div className="set" >
          <div>
            <img src={jsIcon} alt="javascript icon" />
          </div>
          <div>
            <img src={rubyIcon} alt="Ruby icon" />
          </div>
          <div>
            <img src={reactIcon} alt="react icon" />
          </div>
          <div>
            <img src={nodeIcon} alt="node icon" />
          </div>
          <div>
            <img src={reduxIcon} alt="redux icon" />
          </div>
          <div>
            <img src={bootstrapIcon} alt="bootstrap icon" />
          </div>
          <div>
            <img src={firebaseIcon} alt="firebase icon" />
          </div>
          <div>
            <img src={mongoIcon} alt="mongodb icon" />
          </div>
          <div>
            <img src={html5Icon} alt="html5 icon" />
          </div>
          <div>
            <img src={swift} alt="swift icon"/>
          </div>
        </div>

        <div className="set set2">
          <div>
            <img src={jsIcon} alt="javascript icon" />
          </div>
          <div>
            <img src={rubyIcon} alt="Ruby icon" />
          </div>
          <div>
            <img src={reactIcon} alt="react icon" />
          </div>
          <div>
            <img src={nodeIcon} alt="node icon" />
          </div>
          <div>
            <img src={reduxIcon} alt="redux icon" />
          </div>
          <div>
            <img src={bootstrapIcon} alt="bootstrap icon" />
          </div>
          <div>
            <img src={firebaseIcon} alt="firebase icon" />
          </div>
          <div>
            <img src={mongoIcon} alt="mongodb icon" />
          </div>
          <div>
            <img src={html5Icon} alt="html5 icon" />
          </div>
          <div>
            <img src={swift} alt="swift icon"/>
          </div>
        </div>

        <div className="set set3">
          <div>
            <img src={jsIcon} alt="javascript icon" />
          </div>
          <div>
            <img src={rubyIcon} alt="Ruby icon" />
          </div>
          <div>
            <img src={reactIcon} alt="react icon" />
          </div>
          <div>
            <img src={nodeIcon} alt="node icon" />
          </div>
          <div>
            <img src={reduxIcon} alt="redux icon" />
          </div>
          <div>
            <img src={bootstrapIcon} alt="bootstrap icon" />
          </div>
          <div>
            <img src={firebaseIcon} alt="firebase icon" />
          </div>
          <div>
            <img src={mongoIcon} alt="mongodb icon" />
          </div>
          <div>
            <img src={html5Icon} alt="html5 icon" />
          </div>
          <div>
            <img src={swift} alt="swift icon"/>
          </div>
        </div>

      </section>
    </div>
  )
}