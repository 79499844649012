import React, { useState } from 'react'
import Flip from 'react-reveal/Flip'

export default function ProjectCard(props) {

  const [isFlipped, setIsFlipped] = useState(false)

    let project = props.project

    return (
      // <div className="flip-card">
      //   <div className="flip-card-inner j-card">
      <div className="flip-card">
        <div className="flip-card-inner">

          { isFlipped 
          
            ?

            <Flip right >
              <div onClick={(e) => setIsFlipped(false)} className="j-card flip-card-back">
                <div style={{width: '280px', height: '360px', marginTop: '20px'}}>
                  <h1 style={{fontFamily: 'Pacifico, cursive'}}>{project.name}</h1>
                  <div> * * * </div>
                  <div style={{margin: '5px'}}>{ project.description && <h4> {project.description} </h4> }</div>
                  <div> * * * </div>
                  <div style={{margin: '5px'}}>{ project.github && <a target="_blank" rel="noopener noreferrer" href={project.github}>Source Code on GitHub</a> }</div>
                  <div style={{margin: '5px'}}>{ project.site && <a target="_blank" rel="noopener noreferrer" href={project.site}>Live Site</a> }</div>
                </div>
              </div>
            </Flip>

            :
            
            <div onClick={() => setIsFlipped(true)} className="j-card flip-card-front" style={{width: '280px', height: '360px'}}>
              <img src={project.image} alt={project.name} className="j-card-image"/>
            </div>
          
          }

        </div>
      </div>
      //   </div>
      // </div>
    )
  }
