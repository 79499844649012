import React from 'react'

import { Breakpoint } from 'react-socks'
import Nabber from '../components/Nabber'
import DropdownNav from '../components/DropdownNav'

import { Link } from 'react-router-dom'

import Fade from 'react-reveal/Fade'
import Bounce from 'react-reveal/Bounce'

import Container from 'react-bootstrap/Container'

import headshot from '../images/headshot.jpeg'
import ReactGA from 'react-ga'

ReactGA.pageview(window.location.pathname + window.location.search);


export default function AboutMe() {

  const content = () => {
    return (
      <>
        <div style={{fontSize: '2em'}}className="content-section">
          Passion Fuels Purpose.
        </div>

        <div className="content-section">
          NOLA native. I love good music, better food, and great energy.
        </div>

        <div className="content-section">
          I have always been an incredibly creative person and wanted to make things.
        </div>

        <div className="content-section">
          The thing I love most about coding is combining my love of creation with my passion for tech. 
          I wake up every day and think about what I could make that could change the world. 
          I love the transmutation of writing code and having it turn into something on the screen.
        </div>
        <div className="content-section">
          I find figuring out data to be exciting. 
          Opening it up and seeing what makes it work. Picking apart the intricate relationships inside of a database.
        </div>
        
        <div className="content-section">
          I am very proud to be a woman in Tech and I welcome the chance to work on new and challenging projects.
        </div>
      </>
    )
  }

  return (
    <>

      <Breakpoint m down>
        <Nabber />
        <div style={{height: '1150px'}}className="about-me mobile">
          <center>
            <div className="d-flex flex-column">
              <Bounce clear delay={500}>
              <div style={{fontSize: '4em', color: 'navy', fontFamily: 'Gorditas, cursive'}}>About me.</div>
              </Bounce>
              <Container className="d-flex flex-column justify-content-around">
                  
                  <img style={{width: '55%', border: '6px solid white'}} src={headshot} alt="kelley" />
                
                  <div style={{ color: 'navy', background: 'rgba(255, 255, 255, 0.4)'}}>
                    <Bounce clear delay={650}>
                    {content()}
                    </Bounce>
                  </div>
                </Container>
            </div>
          </center>
        </div>
        </Breakpoint>

        <Breakpoint l up>
          <DropdownNav/>
          <Fade bottom duration={750}>
              {/* <Slide bottom duration={750}> */}
          <div className="about-me desktop">
            <center>
              <div className="d-flex flex-column">
                <Bounce clear delay={500}>
                <div style={{fontSize: '7em', color: 'navy', fontFamily: 'Gorditas, cursive'}}>About me.</div>
                </Bounce>
                <Container className="d-flex flex-row justify-content-around align-items-center">
                  
                  <img style={{width: '30%', border: '6px solid white'}} src={headshot} alt="kelley" />
                  
                  <div style={{color: 'navy', margin: '1%'}}>
                    {content()}
                  </div>
                </Container>
                <Link to="/portfolio" style={{color: '#F76A8C', fontSize: '2em', marginTop: '1%', fontFamily: 'Gorditas, cursive'}}>Click to View my Work</Link>
              </div>
            </center>
          </div>
          </Fade>
              {/* </Slide> */}
        </Breakpoint>

    </>
  )
}