import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
// import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'

export default function Nabber() {
  return (
  
    <Navbar id="nabber" bg="light" variant="light" expand="lg" style={{zIndex: '1'}}>
      <Navbar.Brand id="brand"> 
      <Link className="nav-link" to='/' style={{color: '#F76A8C'}}>Kelley Michelle</Link>
      </Navbar.Brand>
        <Navbar.Toggle className="border-0" aria-controls="navbar-toggle" />
          <Navbar.Collapse id="navbar-toggle">
            <Nav className="ml-auto">
              
              <Link className="nav-link" to='/about-me' >About Me</Link>
              <Link className="nav-link" to='/portfolio' >Portfolio</Link>
              <Link className="nav-link" to='/contact' >Contact Me</Link>
              <Nav.Link target="_blank" rel="noopener noreferrer" href="http://blog.kelleymichelle.com">
                Blog
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
    </Navbar>

  )
} 