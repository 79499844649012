import React from 'react'
import ProjectCard from '../components/ProjectCard'

import { Breakpoint } from 'react-socks'

export default function PortfolioTileBox() {

  const projects = [
    {
      name: "Rubik's Time Tracker",
      image: 'https://res.cloudinary.com/color-connection/image/upload/v1587157377/KelleyMichelle-Portfolio/rubiks_kngdlz.png',
      github: 'https://github.com/kelleymichelle/rubikstime',
      site: 'http://rubikstime.com/',
      description: 'React, React Native, JavaScript, Rails API, BootStrap'
    },
    {
      name: 'Color Connection',
      image: 'https://res.cloudinary.com/color-connection/image/upload/v1587157372/KelleyMichelle-Portfolio/colorconnection_2_ufjz8s.png',
      github: 'https://github.com/kelleymichelle/color-connection',
      site: '',
      description: 'React, Redux, JavaScript, Rails API, BootStrap'
    },
    {
      name: 'Project Brainstorm',
      image: 'https://res.cloudinary.com/color-connection/image/upload/v1587157381/KelleyMichelle-Portfolio/brainstorm_2_j6y2h2.png',
      github: 'https://github.com/kelleymichelle/brainstorm',
      site: '',
      description: 'Ruby on Rails, ActiveRecord, SQL'
    },
  
    {
      name: 'Ekkobase',
      image: 'https://res.cloudinary.com/color-connection/image/upload/v1587157384/KelleyMichelle-Portfolio/ekkobase_2_ljj8fx.png',
      github: 'https://github.com/kelleymichelle/echo-base',
      site: 'http://ekkobase.com/',
      description: 'React, JavaScript, BootStrap'
    },
    {
      name: 'Get Driving Now',
      image: 'https://res.cloudinary.com/color-connection/image/upload/v1587157365/KelleyMichelle-Portfolio/getdriving_jinpay.png',
      github: 'https://github.com/kelleymichelle/getdrivingnowreact',
      site: '',
      description: 'React, JavaScript, Rails API, BootStrap'
    },
    {
      name: 'Matchy Match',
      image: 'https://res.cloudinary.com/color-connection/image/upload/v1587157382/KelleyMichelle-Portfolio/matchy_wujjzf.png',
      github: 'https://github.com/kelleymichelle/matchy-match',
      site: 'https://matchymatch.net/',
      description: 'JavaScript SPA, Rails API, BootStrap'
    },
 
  ]

  return (
    <>

    <Breakpoint m >
      <div id="tile-box" className="d-flex" style={{overflowX: 'scroll', height: '700px', width: '650px', marginLeft: '30px', marginBottom: '20px'}}>
        { projects.map((p, i) => <ProjectCard project={p} key={i}/>) }
      </div>
    </Breakpoint>

    <Breakpoint l up>
      <div id="tile-box" className="d-flex" style={{overflowX: 'scroll', width: '1000px', marginBottom: '20px'}}>
        { projects.map((p, i) => <ProjectCard project={p} key={i}/>) }
      </div>
    </Breakpoint>

    <Breakpoint s down>
      <div id="tile-box" className="d-flex" style={{overflowX: 'scroll', height: '550px', width: '360px', marginTop: '25px', marginLeft: '20px', marginRight: '30px', marginBottom: '20px'}}>
        { projects.map((p, i) => <ProjectCard project={p} key={i}/>) }
      </div>
    </Breakpoint>
    </>
  )
}