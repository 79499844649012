import React from 'react'
import {SocialMediaIconsReact} from 'social-media-icons-react'

export default function SocialMediaIcons() {
  return (
    
    <div className="d-flex flex-row justify-content-around" style={{width: '250px'}}>
      <SocialMediaIconsReact borderColor="rgba(0,0,0,0)" borderWidth="5" borderStyle="solid" icon="linkedin" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(247,106,140,1)" iconSize="5" roundness="50%" url="https://www.linkedin.com/in/kelley-chaplain/" size="60" />

      <SocialMediaIconsReact borderColor="rgba(0,0,0,0)" borderWidth="5" borderStyle="solid" icon="twitter" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(247,106,140,1)" iconSize="5" roundness="50%" url="https://twitter.com/kelley_chaplain" size="60" />

      <SocialMediaIconsReact borderColor="rgba(0,0,0,0)" borderWidth="5" borderStyle="solid" icon="github" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(247,106,140,1)" iconSize="5" roundness="50%" url="https://github.com/kelleymichelle" size="60" />
    </div>

  )
}