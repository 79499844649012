import React from 'react'
import { Link } from 'react-router-dom'
import { Breakpoint } from 'react-socks'
import Nabber from '../components/Nabber'
import DropdownNav from '../components/DropdownNav'

import SocialMediaIcons from '../components/SocialMediaIcons'

import RubberBand from 'react-reveal/RubberBand'

export default function Home(props) {


  return (
    <>
     {/* mobile display */}
      <Breakpoint m down>
        <Nabber />
        <div className="home-page mobile">

      <center>
        <RubberBand delay={500}>
          <div style={{fontSize: '3.5em', marginTop: '35%', marginBottom: '25%'}} className="greeting">
            <div>
              Hi, I'm Kelley.
            </div>
            <div style={{marginTop: '12%'}}>
              I code.
            </div>
          </div>
        </RubberBand>
        <SocialMediaIcons/>  
        </center>
        </div>
      </Breakpoint>

      <Breakpoint l up>
      <DropdownNav/>
      <div style={{height: '950px'}}className="home-page mobile">

        <center>
          <RubberBand delay={500}>
            <div className="greeting" style={{marginBottom: '6%'}}>
              Hi, I'm Kelley.
              <br/>
              I code.
            </div>
          </RubberBand>
          <SocialMediaIcons/>  
          
            <div className="continue">
              {/* <div onClick={props.smoothScroll('#first')}>Click to Continue</div> */}
              <Link to="/about-me" style={{color: '#F76A8C', fontFamily: 'Gorditas, cursive'}}>Click to Continue</Link>
            </div>
           
            </center>
      </div>
          </Breakpoint>

       
       
    </>
  )
}