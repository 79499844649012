import React from 'react'

import emailjs from 'emailjs-com'

import SocialMediaIcons from '../components/SocialMediaIcons'

import { Breakpoint } from 'react-socks'
import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom'
// import { Link } from 'react-router-dom'
import Nabber from '../components/Nabber'
import DropdownNav from '../components/DropdownNav'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import ReactGA from 'react-ga'

ReactGA.pageview(window.location.pathname + window.location.search);

export default class ContactMe extends React.Component {

  state = {
    senderName: '',
    email: '',
    message: '',
    disabled: false,
    emailSent: null,
    errors: []
  }

  handleChange = e => {
    console.log(e.target.name, e.target.value)
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  validateForm = input => {
    const errorsArray = []
    const { senderName, email, message } = input
  
    const emailResult = email.includes("@") && email.length > 5
      if ( senderName.length < 2 ) {
        errorsArray.push("Please enter a valid name")
      }
      if (!emailResult) {
        errorsArray.push("Please enter a valid email")
      } 
      if ( message.length < 2 ) {
        errorsArray.push("Please enter a message")
      }
      
      if ( errorsArray.length > 0 ) {
        this.setState({
          errors: errorsArray
        })
      } else {
        this.sendEmail()
      }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.validateForm(this.state)

  }

  sendEmail = () => {
    emailjs.send('kchaplaingmail','template_D7csONbu', this.state, 'user_5N7ugmQvAYqu5Hi0gppCZ')
      .then((response) => {
          console.log('SUCCESS!', response.status, response.text)
          this.setState({
            disabled: true, 
            emailSent: true,
            senderName: '',
            email: '',
            message: ''
          })
      }, (err) => {
          console.log('FAILED...', err);
          this.setState({
            emailSent: false
          })
      });
  }

  

  render() {

    const errorMessages = (
      <center>
        <div>
          {this.state.errors.map(e => <p>{e}</p>)}
        </div>
      </center>
    )

    const heading = (
      <center>
        <div style={{fontFamily: 'Gorditas, cursive', color: 'white'}}>
           
              <div style={{margin: '1.5%', fontSize: '1.25em'}}> 
                <Zoom right cascade delay={750}>
                  Let's build something together! Send me a message below or connect with me on social media! 
                </Zoom>
              </div>
           
            <div style={{margin: '1.5%', marginTop: '2%'}}><SocialMediaIcons/></div>
        </div>
      </center>
    )

    const emailForm = 
    (
      <center>
      <div id="contact-form">
        { this.state.errors && errorMessages }
        <Form onSubmit={this.handleSubmit}>
          <div className="d-flex flex-wrap justify-content-center">
          <Form.Group>
            <Form.Label htmlFor="name" style={{color: '#212850'}}>Full Name</Form.Label>
            <Form.Control id="name" name="senderName" type="text" value={this.state.senderName} onChange={this.handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="email" style={{color: '#212850'}}>Email Address</Form.Label>
            <Form.Control id="email" name="email" type="email" value={this.state.email} onChange={this.handleChange} />
          </Form.Group>
          </div>
          <Form.Group>
            <Form.Label htmlFor="message" style={{color: '#212850'}}>Please Leave a Message</Form.Label>
            <Form.Control id="message" name="message" as="textarea"rows="5" value={this.state.message} onChange={this.handleChange} />
          </Form.Group>
          <Button size="lg" className="d-inline-block" style={{backgroundColor: '#F76A8C', fontFamily: 'Gorditas, cursive', border: 'none'}} type="submit" disabled={this.state.disabled}>
              Send
          </Button>
        {/* {this.state.emailSent === true && <p className="d-inline success-msg">Email Sent</p>} */}
        {this.state.emailSent === false && <p className="d-inline err-msg">Email Not Sent</p>}
      </Form>
    </div>
    </center>
    )

    const successMsg = (
      <div style={{fontFamily: 'Gorditas, cursive', color: 'white', fontSize: '2em', margin: '5%'}}>
        Your message has been sent! I will be in touch soon! :)
      </div>
    )

    return (
      <>
      <Breakpoint m down>
        <Nabber/>
        <div style={{height: '1100px'}} className="contact mobile">
        <center>
          <div className="d-flex flex-column">
            <div style={{color: 'white', fontSize: '4em', marginTop: "5%", fontFamily: 'Gorditas, cursive'}}>Get in Touch</div>
              {heading}
              { this.state.emailSent ? successMsg : emailForm }
            
          </div>
        </center>
      </div>
    </Breakpoint>

    <Breakpoint l up>
      <DropdownNav/>
      <Fade bottom duration={1000}>
      <div style={{height: '900px'}}className="contact desktop">
        <center>
          <div className="d-flex flex-column">
            <div style={{color: 'white', fontSize: '7em', marginTop: "3%", fontFamily: 'Gorditas, cursive'}}>Get in Touch</div>
              {heading}
              { this.state.emailSent ? successMsg : emailForm }
            
          
          </div>
          {/* <Link to="/contact" style={{color: '#F76A8C', fontSize: '2em', marginTop: '10%'}}>Click to Get in Touch</Link> */}
        </center>
      </div>
      </Fade>
    </Breakpoint>
   </>
    )
  }
}